<template lang="pug">
.wf-table.full-box
  DataTable(
    v-if="showTable"
    ref="dataTable"
    serverSide
    resource="/sepProStat/list"
    :defaultPageSize="9999"
    :needFilterMargin="true"
    :hasPage="false"
    :filterFields="filterFields"
    :defaultFilterBy="defaultFilterBy"
    :settingButtonList="[]"
    :columns="columns")
    template(slot="prep")
      h3.table-title 应收水费分项统计表
</template>

<script>
/**
 * 应收水费分项统计管理
 */
import { mapActions } from 'vuex'
export default {
  name: 'SepProStatReport',
  data () {
    return {
      showTable: false,
      defaultFilterBy: {
        settlementMonth: this.$day(this.$day().format('YYYY-MM')).valueOf(),
        orgId: ''
      },
      // 表格筛选表单配置
      filterFields: [{
        name: 'settlementMonth',
        label: '结算月份',
        form: {
          tag: 'date',
          dateType: 'month',
          filterFunc: ({ name, value }) => {
            return [{
              name: name,
              type: 'eq',
              value: this.$day(value).format('YYYY-MM')
            }]
          }
        }
      },
      {
        name: 'orgId',
        label: '水厂',
        form: {
          tag: 'select',
          filterable: true,
          relation: 'waterWorks'
        }
      }],
      // 表格列配置
      columns: [
        { name: 'usenature', label: '用水性质', width: 120 },
        { name: 'meterReadingYear', label: '抄表年', minWidth: 100 },
        { name: 'meterReadingMonth', label: '抄表月', minWidth: 100 },
        // { name: 'settlementDate', label: '结算月份', minWidth: 100 },
        // { name: 'orgName', label: '水厂', width: 120 },
        { name: 'totalUsewaterCount', label: '户数', minWidth: 100 },
        { name: 'totalQuantity', label: '水量', minWidth: 100 },
        { name: 'totalWater', label: '水费', minWidth: 100 },
        { name: 'totalSewage', label: '污水费', minWidth: 120 },
        { name: 'totalWaterResource', label: '水资源费', minWidth: 100 },
        { name: 'totalPenalty', label: '违约金', minWidth: 100 },
        { name: 'totalAmount', label: '总金额 ', minWidth: 100 }
      ]
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    getData () {
      this.$refs.dataTable.reRenderTableData()
    },
    renderRelation () {
      this.getRelations(['waterWorks'])
        .then(res => {
          this.$renderRelationColumns(res, [this.filterFields])
          const orgId = JSON.parse(localStorage.RIGHTS).orgPath.split(',')[2]
          const result = orgId ? Number(orgId) : res.waterWorks.find(i => i.parentId)?.value
          this.$set(this.defaultFilterBy, 'orgId', result)
          this.$nextTick(() => { this.showTable = true })
        })
    }
  },
  created () {
    this.renderRelation()
  }
}
</script>

  <style lang="sass" scoped>
  .wf-table
    display: flex
    flex: 1
    flex-direction: column
    background-color: #eee
    width: 100%
    height: 100%
    .table-title
      text-align: center
      padding-bottom: 8px
    ::v-deep
      .datatablepage-filter
        .dataform-button-item
          min-width: unset !important
  </style>
